import React, { useState, useEffect, useCallback } from 'react'
import Button from '../Components/Button/Button';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import "react-toastify/dist/ReactToastify.css";
import Input from '../Components/Inputs/Input'
import ItemCount from '../Components/Inputs/ItemCount';
import './Details.css'
var temp;
temp = { item: "", count: "" }
function ItemDetails({ setTab, itemDetails, setItemDetails, setActive }) {
    const [disable, setDisable] = useState(true)
    const [details, setDetails] = useState({
        businessName: "",
        itemCount: 1,

    });
    const [initialCount, setInitialCount] = useState(0)
    const [trues, setTrues] = useState(0)
    const [tempArr, setTempArr] = useState([])
    const [itemArr, setItemArr] = useState([])
    let t = itemArr
    //   updateCount
    useEffect(() => {

    }, [initialCount])

    const deleteItem = (indi) => {
        setDetails({ ...details, itemCount: details.itemCount - 1 })
        // setDetails({
        //     ...details,
        //     "itemCount":details.itemCount - 1,
        // });
        setInitialCount(initialCount => initialCount - 1)
        const x = itemArr.filter((item, index) => {
            if (index !== indi) return item
        })
        itemArr.filter((item, index) => {
            if (index !== indi) return item
        })
        setItemArr(x)

    }
    const getValuesInParent = (index, value, quantity, note) => {


        if (value !== '')
            setTempArr([...tempArr, { item: value, count: quantity, note: note }])
        itemArr[index] = { item: value, count: quantity, note: note }

    }
    const disablebtn = () => {
        setDisable(true)
    }
    const checkInputs = () => {
        if (details.itemCount !== 0 && details.businessName !== "") {
            // if(){
            setDisable(false)
        }
        else {
            setDisable(true)
            // }
        }

    }

    useEffect(() => {
        checkInputs()

    }, [details, itemArr])
    const updateCount = (count) => {
        return setInitialCount(prev => prev = parseInt(count))
    }

    const checkItemCount = () => {

        if (details.itemCount !== (0 || NaN || "")) {
            updateCount(details.itemCount)
        }
        if (details.itemCount > initialCount) {

            updateCount(parseInt(details.itemCount))
        }
        if (details.itemCount == initialCount) {

        }
    }
    let d = []

    const [addAr, setAddAr] = useState([])
    useEffect(() => {

        if (details.itemCount !== 0) {
            checkItemCount()
        }
        //    setAddAr([...addAr,...itemArr])
        // setItemDetails(addAr,details)
        // itemDetails.push()
        // setItemArr([])
    }, [details.itemCount])
    const getAddAr = () => {

        setItemDetails(itemArr, details)
    }
    const handleChange = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value,
        });

        if (e.target.value > 100) {
            // Notify.failure('Please Enter Count Less Than 100');
            // toast.error("Please Enter Less Than 100", {
            //     position: toast.POSITION.TOP_LEFT
            //   });
            setDetails({ ...details, itemCount: 100 })
        } if (e.target.value < 1) {
            // Notify.failure('Count must be greater than 0');
            // toast.error("Please Greater Than 0 ", {
            //     position: toast.POSITION.TOP_LEFT
            //   });

            setDetails({ ...details, itemCount: 0 })
        }

        if (details.businessName !== "") { setDisable(false) }
    };
    const getStep = () => {
        setItemDetails(itemArr, details)
        // setActive("2")
        setTab("customer")
    }
    // const notify = () => {
    //   Notify.success('Sol lucet omnibus');

    // }
    const validateInputs = () => {
        if (details.businessName !== "" && details.itemCount !== 0) {
            return
        }
        else {
            if (details.businessName == "") {
                Notify.failure('Please Enter Business Name');

                // toast.error("Please Enter Business Name", {
                //     position: toast.POSITION.TOP_LEFT
                //   });
            }
            if (details.itemCount == 0) {
                // toast.error("Please Enter Count", {
                //     position: toast.POSITION.TOP_LEFT
                //   });
                Notify.failure('Please Enter Count');

            }
        }
    }

    return (

        <>
            <div className='layout'> <div className='details'>
                <Input bname="Business Name" name="businessName" min={0} maxlength={40} placeholder="Example: Joe Blow Gym" type="text" handleChange={handleChange} />
                <Input bname="Number of items" min={0} maxlength={100} val={details.itemCount} name="itemCount" placeholder="Please Enter The Number Of Items" type="number" handleChange={handleChange} />
                <div className='count-container'>
                    {
                        Array.from({ length: initialCount }).map((name, index) => {
                            return <ItemCount deleteItem={deleteItem} key={index} addAr={addAr} getAddAr={getAddAr} name={name} onSelect={getValuesInParent} indi={index} />
                        })
                    }
                </div>


                {/* <div>
                    <hr></hr>
                      {itemArr.map(items=>{
                        return <div>{items.item +" fwewef " +items.count }</div>  
                    })}
                </div> */}
            </div>
                <Button name="Proceed Next" funcs={validateInputs} disable={disable} func={getStep} />
            </div>

        </>

    )
}

export default ItemDetails

// 